<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://lanista.com.tr"
        target="_blank"
      >Lanista Yazılım</b-link>
      <span class="d-none d-sm-inline-block">, Tüm hakları saklıdır</span>
    </span>

    <b-link
      class="float-md-right"
      href="https://lanista.com.tr"
      target="_blank"
    >
      <b-img
        src="/black.png"
        width="80"
      />
    </b-link>
  </p>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BImg,
  },
}
</script>
