export default [
  // {
  //   title: 'Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
  {
    title: 'Müşteri İşlemleri',
    icon: 'UserIcon',
    resource: 'customer',
    action: 'all',
    children: [
      {
        title: 'Müşteriler',
        route: 'customer-list',
        icon: 'ListIcon',
        resource: 'customer',
        action: 'all',
      },
      {
        title: 'Müşteri Ekle',
        route: 'customer-add',
        icon: 'PlusCircleIcon',
        resource: 'customer',
        action: 'all',
      },
    ],
  },
  {
    title: 'Kullanıcı İşlemleri',
    icon: 'UserIcon',
    resource: 'user',
    action: 'all',
    children: [
      {
        title: 'Kullanıcı Listesi',
        route: 'user-list',
        icon: 'ListIcon',
        resource: 'user',
        action: 'all',
      },
      {
        title: 'Kullanıcı Ekle',
        route: 'user-create',
        icon: 'PlusCircleIcon',
        resource: 'user',
        action: 'all',
      },
    ],
  },
  {
    title: 'Ürün İşlemleri',
    icon: 'ShoppingCartIcon',
    resource: 'customer',
    action: 'all',
    children: [
      {
        title: 'Ürün Listesi',
        route: 'product-list',
        icon: 'ListIcon',
        resource: 'customer',
        action: 'all',
      },
      {
        title: 'Ürün İşlemleri',
        route: 'product-operations',
        icon: 'SettingsIcon',
        resource: 'customer',
        action: 'all',
      },
      {
        title: 'Öne çıkan ürünler',
        route: 'featured',
        icon: 'LayoutIcon',
        resource: 'featured',
        action: 'all',
      },
      {
        title: 'Gösterilen ürünler',
        route: 'selected',
        icon: 'EyeIcon',
        resource: 'featured',
        action: 'all',
      },
    ],
  },
  {
    title: 'Ürün Listesi',
    route: 'products',
    icon: 'ListIcon',
    resource: 'products',
    action: 'all',
  },
  {
    title: 'Sepetim',
    route: 'checkout',
    icon: 'ShoppingBagIcon',
    resource: 'products',
    action: 'all',
  },

  {
    title: 'Sipariş İşlemleri',
    icon: 'InboxIcon',
    children: [
      {
        title: 'Sipariş Listesi',
        route: 'allorders-list',
        icon: 'ListIcon',
        resource: 'operation',
        action: 'all',
      },
      {
        title: 'Siparişler',
        route: 'invoice-list',
        icon: 'PackageIcon',
        resource: 'invoiceList',
        action: 'all',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: 'products',
    icon: 'ListIcon',
    resource: 'guest',
    action: 'all',
  },
  {
    title: 'Sepet',
    route: 'checkout',
    icon: 'ShoppingBagIcon',
    resource: 'guest',
    action: 'all',
  },
]
